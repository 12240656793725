import React from "react";
import Header from "./components/Header";
import "./index.css";


const App = () => {
  return (
    <div className="container">
      <Header />
    </div>
  )
};

export default App;
